import React, { useEffect, useState } from 'react'
import FeaturedCard from './FeaturedCard'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

import  { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import axios from 'axios'

function Featured() {

    SwiperCore.use([Navigation, Autoplay]);

    const [data,setData] = useState('')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}api/getHighLights`)
            .then((res) => {
                setData(res.data.data.package)
            })
    },[])

    return (
        <>
            <div className="featured">
                <Swiper
                slidesPerView={1}
                loop={true}
                navigation={
                    { 
                    prevEl: '.featured__leftBtn',
                    nextEl: '.featured__rightBtn',}
                }
                autoplay= {
                {delay: 4500,
                disableOnInteraction: true,
                    }
                }
                >
                    {
                        data || Array.isArray(data) ?
                        data.map((d,i) => (
                            <SwiperSlide key= {i}>
                                <FeaturedCard id= {d.id} coverImage= {d.coverImage} title= {d.title} companyName= {d.companyName} about= {d.aboutPackage} duration= {d.Duration} includes= {d.includes} photos= {d.photos} />
                            </SwiperSlide>
                        ))
                        :''
                    }
                   
                </Swiper>
                <div className="featured__btnWrapper">
                    <div className="featured__leftBtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="69" height="68" viewBox="0 0 69 68">
                            <g id="Group_453" data-name="Group 453" transform="translate(-0.46 -0.158)">
                            <rect id="Rectangle_85" data-name="Rectangle 85" width="69" height="68" transform="translate(0.46 0.158)" fill="#ebb12c"/>
                            <path id="Path_3" data-name="Path 3" d="M14.9,4l1.922,1.922L9.22,13.54H25.807v2.726H9.22l7.605,7.619L14.9,25.807,4,14.9Z" transform="translate(19.647 19.113)" fill="#e2eaf0"/>
                            </g>
                        </svg>
                    </div>
                    <div className="featured__rightBtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
                            <g id="Group_454" data-name="Group 454" transform="translate(0.451 -0.158)">
                            <rect id="Rectangle_84" data-name="Rectangle 84" width="68" height="68" transform="translate(-0.451 0.158)" fill="#ebb12c"/>
                            <path id="Path_3" data-name="Path 3" d="M14.9,4,12.982,5.922l7.605,7.619H4v2.726H20.587l-7.605,7.619L14.9,25.807l10.9-10.9Z" transform="translate(18.697 19.113)" fill="#e2eaf0"/>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Featured
