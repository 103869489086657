import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react'
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PackagePop({formState, packId, quickId, esewaCode, bookModal, setBookModal, name, setName, email, setEmail, phone, setPhone, occu, setOccu, rate, setRate, stDate, endDate, bgImg}) {

    useEffect(() => {
        setRate(rate)
    },[])

    const customModalStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
            backgroundImage: 'linear-gradient(105deg, rgba(#E0E0E0, 0.6) 0%, rgba(#E0E0E0, 0.6) 50%, transparent 50%)',
          transform: 'translate(-50%, -50%)',
        },
      };

    const handleOccu = (cond) => {
        let occ = occu;
        if(cond==="add"){
            occ++;
            setOccu(occ)
        }else if(cond==="min"){
            if(occ<=1){
                return
            }else {
                occ--;
                setOccu(occ)
            }
        }
    }

    const handleSubmit = () => {

        if(name.length <=0 ) {
            toast.error("Name field cannot be empty.")
        }else if(email.length <=0 ) {
            toast.error("Email field cannot be empty.")
        }else if(phone.length <=0 ) {
            toast.error("Phone field cannot be empty.")
        }else{
            if(formState === 'enq') {
                let bodyFormData = new FormData();
        
                bodyFormData.append('name', name)
                bodyFormData.append('email', email)
                bodyFormData.append('phone', phone)
                bodyFormData.append('noOfGuests', occu)
                bodyFormData.append('package_id', packId)
                bodyFormData.append('quickdate_id', quickId)
                bodyFormData.append('stdate', moment(stDate).format('YYYY-MM-DD'))
                bodyFormData.append('enddate', moment(endDate).format('YYYY-MM-DD'))
      
        
                axios({
                    method: "post",
                    url: `${process.env.REACT_APP_BASEURL}api/makeBooking`,
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data" },
                  }).then((res) => {
                    toast.success(res.data.message);
                    setBookModal(false)
                }).catch((e) => {
                      console.log(e.message)
                      toast.error(e.message)
                    setBookModal(false)
                })
            }else if(formState === 'quck') {

                if(esewaCode.length > 1){

                    let bodyFormData = new FormData();
                    bodyFormData.append('name', name)
                    bodyFormData.append('email', email)
                    bodyFormData.append('phone', phone)
                    bodyFormData.append('noOfGuests', occu)
                    bodyFormData.append('billedAmount', rate*occu)
                    bodyFormData.append('package_id', packId)
                    bodyFormData.append('quickdate_id', quickId)
                    bodyFormData.append('stdate', moment(stDate).format('YYYY-MM-DD'))
                    bodyFormData.append('enddate', moment(endDate).format('YYYY-MM-DD'))
                    
                    axios({
                        method: "post",
                        url: `${process.env.REACT_APP_BASEURL}api/makeBooking`,
                        data: bodyFormData,
                        headers: { "Content-Type": "multipart/form-data" },
                      }).then((res) => {
                        let params= {
                            amt: rate*occu,
                            psc: 0,
                            pdc: 0,
                            txAmt: 0,
                            tAmt: rate*occu,
                            pid: res.data.uuid,
                            scd: esewaCode,
                            su: `${window.location.href}`,
                            fu: `${window.location.href}`
                        }
        
                        var form = document.createElement("form");
                        form.setAttribute("method", "POST");
                        form.setAttribute("action", 'https://esewa.com.np/epay/main');
                    
                        for(var key in params) {
                            var hiddenField = document.createElement("input");
                            hiddenField.setAttribute("type", "hidden");
                            hiddenField.setAttribute("name", key);
                            hiddenField.setAttribute("value", params[key]);
                            form.appendChild(hiddenField);
                        }
                    
                        document.body.appendChild(form);
                        form.submit();
                        setBookModal(false)
                    }).catch((e) => {
                        alert(e.message)
                        setBookModal(false)
                    })
                }else {
                    let bodyFormData = new FormData();
        
                    bodyFormData.append('name', name)
                    bodyFormData.append('email', email)
                    bodyFormData.append('phone', phone)
                    bodyFormData.append('noOfGuests', occu)
                    bodyFormData.append('stdate', moment(stDate).format('YYYY-MM-DD'))
                    bodyFormData.append('enddate', moment(endDate).format('YYYY-MM-DD'))
                    bodyFormData.append('package_id', packId)
                    bodyFormData.append('quickdate_id', quickId)
                    axios({
                        method: "post",
                        url: `${process.env.REACT_APP_BASEURL}api/makeBooking`,
                        data: bodyFormData,
                        headers: { "Content-Type": "multipart/form-data" },
                      }).then((res) => {
                        toast.success(res.data.message);
                        setBookModal(false)
                    }).catch((e) => {
                          console.log(e.message)
                          toast.error(e.message)
                          setBookModal(false)
                    })
                }
            }else {
                toast.error("Invalid Form")
                setBookModal(false)
            }
        }
    }

    return (
        <>
        <ToastContainer/>
            <Modal
                isOpen={bookModal}
                onRequestClose={() => setBookModal(false)}
                style={customModalStyles}
                contentLabel="Example Modal"
                className="packDet__modal"
                overlayClassName= "packDet__modal-overlay"
            >
                <div className= "packDet__bg" style= {{backgroundImage: `url(${process.env.REACT_APP_BASEURL}images/${bgImg})`}}>
                    <div className= "packDet__bgOverlay"></div>
                </div>
                <div className="packDet__form">
                    <div className="packBook__detail-head" style= {{marginBottom: '1.7rem', width: '90%'}}>
                        <p className="heading-sm" style= {{ color: '#ebb12c'}} >Booking Details</p>
                    </div>
                    <div className="packBook__detail-wrap" style= {{marginBottom: '2rem'}}>
                        <div className="packBook__detail-item">
                            <div className="para-small" style= {{ color: '#fff'}}>Date</div>
                            <div className="para-small" style= {{ color: '#fff'}}>{moment(stDate).format('MMM D')} - {moment(endDate).format('MMM D')}</div>
                        </div>
                        <div className="packBook__detail-item">
                            <div className="para-small" style= {{ color: '#fff'}}>Duration</div>
                            <div className="para-small" style= {{ color: '#fff'}}>{moment(endDate).diff(moment(stDate),'days')} DAYS {moment(endDate).diff(moment(stDate),'days')-1} NIGHTS</div>
                        </div>
                        <div className="packBook__detail-item">
                            <div className="para-small" style= {{ color: '#fff'}}>Number of Guest</div>
                            <div className="para-small" style= {{ color: '#fff', display: 'flex', alignItems: 'center' }}><button className= "btn-operBtn" onClick={() => handleOccu("min")}>-</button> <span style= {{margin: '0 10px'}}>{occu} Adults</span><button className= "btn-operBtn" onClick={() => handleOccu("add")}>+</button></div>
                        </div>
                    </div>
                    
                    <p className="heading-sm" style= {{marginBottom: '1.7rem', color: '#ebb12c'}} >Guest Details</p>
                    <input type="text" placeholder= "Full Name" value= {name} className="input-borNorm" style= {{width: '90%'}} onChange= {(e) => setName(e.target.value)} required />
                    <input type="number" placeholder= "Contact Number" value= {phone} className="input-borNorm" style= {{width: '90%'}} onChange= {(e) => setPhone(e.target.value)} required />
                    <input type="email" placeholder= "Email Address" value= {email} className="input-borNorm" style= {{width: '90%', marginBottom: '3rem'}} onChange= {(e) => setEmail(e.target.value)} required />

                    <button onClick= {handleSubmit} className="btn-color" style={{color: '#050505'}}>Book Now</button>
                </div>
            </Modal>
        </>
    )
}

export default PackagePop
