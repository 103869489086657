import React, { useEffect, useState } from 'react'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

import  { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import axios from 'axios';

function Testimonials() {
    SwiperCore.use([Navigation, Autoplay]);

    const [testi,setTesti] = useState('')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}api/getTestimonials`)
            .then((res) => {
                setTesti(res.data.data)
            })
    },[])

    return (
        <>
            <section className="testimonials">
                <div className="testimonials__slider" >
                    <Swiper
                        slidesPerView={1}
                        loop={true}
                        navigation={
                            { 
                             nextEl: '.testimonials__arrLeft',
                             prevEl: '.testimonials__arrRight',}
                         }
                        autoplay= {
                        {delay: 6500,
                        disableOnInteraction: true,
                            }
                        }
                    >
                        {
                            Array.isArray(testi) ?
                            testi.map((test,ind) => (
                                <SwiperSlide key={ind}>
                                    <div className="testimonials__slide">
                                        <img src={`${process.env.REACT_APP_BASEURL}images/`+test.image} alt="" className="testimonials__slide-img" />
                                        <p className="test-name" style={{marginBottom: "30px"}}>{test.name}</p>
                                        <p className="test-desc" style={{width: "75%"}}>"{test.message}</p>
                                    </div>
                                </SwiperSlide>
                            ))
                            : ''
                        }
                    
                    </Swiper>
                    <div className="testimonials__arrRight">   
                        <svg id="ic_keyboard_arrow_right_24px" xmlns="http://www.w3.org/2000/svg" width="22.054" height="35.715" viewBox="0 0 22.054 35.715">
                            <path id="Path_4" data-name="Path 4" d="M8.59,37.268,22.221,23.607,8.59,9.946l4.2-4.2L30.644,23.607,12.786,41.465Z" transform="translate(-8.59 -5.75)" fill="#ebb12c"/>
                        </svg>               
                    </div>
                    <div className="testimonials__arrLeft">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22.674" height="36.094" viewBox="0 0 22.674 36.094">
                        <g id="Group_272" data-name="Group 272" transform="translate(319.247 -332.684) rotate(179)">
                            <g id="ic_keyboard_arrow_right_24px" transform="translate(302.958 -363.161)">
                            <path id="Path_4" data-name="Path 4" d="M8.59,37.268,22.221,23.607,8.59,9.946l4.2-4.2L30.644,23.607,12.786,41.465Z" transform="translate(-8.59 -5.75)" fill="#ebb12c"/>
                            </g>
                        </g>
                    </svg>  
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonials
