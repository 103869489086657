import React from 'react'
import ProdCard from './ProdCard'

function Popular({data}) {
    return (
        <>
            <div className="popular">
                <p className="heading-main" style={{marginBottom: "20px"}}>Most Popular</p>
                <div className="popular__wrapper">
                    {
                        Array.isArray(data) ?
                        data.map((d,i) => (
                            <ProdCard data={d} key={i}/>
                        ))
                        : ''
                    }
                </div>
            </div>
        </>
    )
}

export default Popular
