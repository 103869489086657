import moment from 'moment'
import React from 'react'

function PackQuickDates({setQuickId, quickDateId,setPackId, packId, setFormState, setBookModal, startDate, endDate, rate, occupancy, setStDate, setEndDate, setRate, setOccu }) {
    const handleSelect = () => {
        setStDate(new Date(startDate))
        setEndDate(new Date(endDate))
        setFormState('quck')
        setRate(rate);
        setOccu(occupancy);
        setQuickId(quickDateId);
        setPackId(packId);
        setBookModal(true);
    }

    return (
        <>
        <div className="packDet__incl-card">
            <p className="para-black" style={{ marginBottom: ".7rem" }}>
            {moment(startDate).format('MMMM D, YYYY')}
            </p>
            <p className="para-black" style={{ marginBottom: ".7rem" }}>
                {moment(endDate).diff(moment(startDate),'days')} DAYS {moment(endDate).diff(moment(startDate),'days')-1} NIGHTS
            </p>
            <p className="para-black" style={{ marginBottom: "2rem" }}>
            Rs {rate} / Per Person
            </p>
            <button className="btn-color" onClick= {handleSelect}>Select</button>
        </div>
        </>
    )
}

export default PackQuickDates
