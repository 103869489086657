import React from 'react'

function HeaderCard({img, title, color}) {
    return (
        <>
            <div className="header__card">
                <img src={`${process.env.REACT_APP_BASEURL}images/`+img} alt="" className="header__card-img" />
                <div className="header__card-cta" style={{backgroundColor: color}}>
                    <p className="para-wh">{title}</p>
                    <div className="header__card-arr">
                        <svg id="ic_keyboard_arrow_right_24px" xmlns="http://www.w3.org/2000/svg" width="8.794" height="14.241" viewBox="0 0 8.794 14.241">
                            <path id="Path_4" data-name="Path 4" d="M8.59,18.318l5.435-5.447L8.59,7.423,10.263,5.75l7.121,7.121-7.121,7.121Z" transform="translate(-8.59 -5.75)" fill="#ebb948"/>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderCard
