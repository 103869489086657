import React, { useEffect, useState } from 'react'

import HeaderCard from './HeaderCard';

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

import  { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Header() {
    SwiperCore.use([Navigation, Autoplay]);

    const [banner,setBanner] = useState('')
    const [comp,setComp] = useState('')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}api/getBanners`)
            .then((res) => {
                setBanner(res.data.data)
            })

        axios.get(`${process.env.REACT_APP_BASEURL}api/getCompanies/all`)
        .then((res) => {
            setComp(res.data.data)
        })
    },[])

    return (
        <>
            <header className="header">
                <div className="banner">
                    <Swiper
                    slidesPerView={1}
                    loop={true}
                    navigation={
                        { 
                        prevEl: '.banner__leftBtn',
                        nextEl: '.banner__rightBtn',}
                    }
                    autoplay= {
                    {delay: 4500,
                    disableOnInteraction: true,
                        }
                    }
                    >
                        {
                            banner || Array.isArray(banner) ?
                            banner.map((ban,ind) => (
                                <SwiperSlide key= {ind}>
                                    <div className="banner__slide">
                                        <img src={`${process.env.REACT_APP_BASEURL}images/`+ban.bannerImage} alt="Banner Background" className="banner__img" />
                                        <div className="banner__overlay"></div>
                                        <div className="banner__text">
                                            <p className="banner-main">{ban.title}</p>
                                            <p className="banner-sub">{ban.subTitle}</p>
                                            <p className="banner-cate">{ban.desc}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                            :''
                        }
             
                    </Swiper>
                    <div className="banner__leftBtn">
                        <svg id="ic_keyboard_arrow_right_24px" xmlns="http://www.w3.org/2000/svg" width="8.794" height="14.241" viewBox="0 0 8.794 14.241">
                            <path id="Path_4" data-name="Path 4" d="M8.59,18.318l5.435-5.447L8.59,7.423,10.263,5.75l7.121,7.121-7.121,7.121Z" transform="translate(-8.59 -5.75)" fill="#fff"/>
                        </svg>
                    </div>
                    <div className="banner__rightBtn">
                        <svg id="ic_keyboard_arrow_right_24px" xmlns="http://www.w3.org/2000/svg" width="8.794" height="14.241" viewBox="0 0 8.794 14.241">
                            <path id="Path_4" data-name="Path 4" d="M8.59,18.318l5.435-5.447L8.59,7.423,10.263,5.75l7.121,7.121-7.121,7.121Z" transform="translate(-8.59 -5.75)" fill="#fff"/>
                        </svg>
                    </div>
                </div>
                {
                    window.innerWidth > 830 ?
                    <div className="header__cards">
                        {
                            comp || Array.isArray(comp) ?
                            comp.map((c, i) => (
                                <SwiperSlide key= {i}>
                                    <Link exact to= {`/company/${c.id}`} className= "para-wh">
                                        <HeaderCard img={c.bannerImage} title={c.companyName} color="#EBB948"/>
                                    </Link>
                                </SwiperSlide>
                            ))
                            :''
                        }
                    </div>
                    :
                    <div className="header__cards">
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={  window.innerWidth > 670 ? 3 : 2}
                            loop={true}
                            autoplay= {
                            {delay: 5500,
                            disableOnInteraction: true,
                                }
                            }
                        >
                            {
                                comp || Array.isArray(comp) ?
                                comp.map((c, i) => (
                                    <SwiperSlide key= {i}>
                                        <Link exact to= {`/company/${c.id}`} className= "para-wh">
                                            <HeaderCard img={c.bannerImage} title={c.companyName} color="#EBB948"/>
                                        </Link>
                                    </SwiperSlide>
                                ))
                                :''
                            }
                        </Swiper>
                    </div>
                }
            </header>
        </>
    )
}

export default Header
