import React from 'react'

function HotDealCard({id,title,coverImage}) {
    return (
        <>
            <div className="hotDealCard">
                <img src={`${process.env.REACT_APP_BASEURL}images/`+coverImage} alt="DEals" className="hotDealCard__img" />
                <div className="hotDealCard__text">
                    <p className="heading-sm--wh">{title}</p>
                </div>
            </div>  
        </>
    )
}

export default HotDealCard
