import React, { useEffect } from 'react'
import  {  SwiperSlide } from 'swiper/react';

function ExploreVideo({v,i, setVideoId,  setOpen}) {
  

    useEffect(() => {
        if(v){
            var video_id = v.link.split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if(ampersandPosition !== -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }
            setVideoId(video_id)
        }
    },[v])

    return (
        <>
            <SwiperSlide key= {i}>
                <div className="explore__slide" onClick={()=> setOpen(true)}>
                    <img src={`${process.env.REACT_APP_BASEURL}images/`+v.thumbnail} alt="explore" className="explore__slide-img" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                        <path id="Path_9" data-name="Path 9" d="M20,33l12-9L20,15ZM24,4A20,20,0,1,0,44,24,19.994,19.994,0,0,0,24,4Zm0,36A16,16,0,1,1,40,24,16.021,16.021,0,0,1,24,40Z" transform="translate(-4 -4)" fill="#f6f6f6"/>
                    </svg>
                </div>
            </SwiperSlide>
        </>
    )
}

export default ExploreVideo
