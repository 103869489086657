import React, { useEffect, useState } from "react";
import axios from "axios";
import PackagePop from "../components/PackagePop";
import PackQuickDates from "../components/PackQuickDates";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "../components/Navbar";
import { toast, ToastContainer } from "react-toastify";

function PackDet({ match }) {
  const {
    params: { id },
  } = match;

  const [data, setData] = useState("");
  const [quickDates, setQuickdates] = useState("");
  const [bookModal, setBookModal] = useState(false);
  const [codes, setCodes] = useState('');
  const [esewaCode, setEsewaCode] = useState('');
  const [formState, setFormState] = useState('enq');

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}api/getPackageDetail/${id}`)
      .then((res) => {
        setQuickdates(res.data.data.quickdates)
        setData(res.data.data.package[0]);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, [id]);

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_BASEURL}api/getCodes`)
    .then((res) => {
      setCodes(res.data.data);
    })
    .catch((e) => console.log(e))
  },[data])

  useEffect(() => {
    if(Array.isArray(codes)){
      codes.map((c) => {
        if(c.companies_id === data.companies_id){
          setEsewaCode(c.code)
        }
        return '';
      })
    }
  },[codes,data])

  const handleEnq = () => {

    if(occu<1) {
      toast.error("Occupancy cannot be less than 1");
    }else {
      setFormState('enq')
      setBookModal(true)
    }

  }

  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [phone,setPhone] = useState('');
  const [occu,setOccu] = useState(1);
  const [rate,setRate] = useState('');
  const [quickId,setQuickId] = useState('');
  const [packId,setPackId] = useState('');
  const [stDate,setStDate] = useState(new Date());
  const [endDate,setEndDate] = useState(moment(new Date()).add(1,'day')._d);

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStDate(start);
    setEndDate(end);
};
  
const [refId,setRefId] = useState(null);
const [oId,setOId] = useState(null);

useEffect(() => {
  
    const params = new URLSearchParams(window.location.search)
      if(params.get('refId')) {
        setRefId(params.get('refId'))
      }

      if(params.get('oid')) {
        setOId(params.get('oid'))
      }
  },[])

  useEffect(() => {
    if(refId && oId) {
      let bodyFormData = new FormData();
      bodyFormData.append('transactionID', refId)
      bodyFormData.append('order_id', oId)
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}api/paymentSuccess`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        toast.success(res.data.message + ', Invoice sent via email.')
      })
      .catch((e) => {
        toast.error(e.message)
        console.log(e)
      })
    }
  },[refId,oId])

  return (
    <>
    
      <Navbar/>
      <ToastContainer/>
      <section className="packDet">
        <div className="packDet__imgBox">
          <img src={`${process.env.REACT_APP_BASEURL}images/`+data.coverImage} alt="Package" className="packDet__img" />
        </div>
        <div className="packDet__details">
          <p className="heading-main" style={{ marginBottom: ".7rem" }}>
            {/* White Water Rafting */}
            {data.title}
          </p>
          <p className="para-deals" style={{ marginBottom: ".7rem" }}>
            {data.companyName}
          </p>
          <p className="para-blk packDet__details-para">{data.aboutPackage}</p>
          <div className="prodCard__rating">
            {Array(Number(data ? data.rating : 0))
              .fill()
              .map((d,i) => (
                <svg
                  id="Group_258"
                  data-name="Group 258"
                  xmlns="http://www.w3.org/2000/svg"
                  width="21.586"
                  height="20.584"
                  viewBox="0 0 21.586 20.584"
                  key={i}
                >
                  <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M21.586,7.976q0-.5-.716-.573l-6.5-.955L11.414.525A.745.745,0,0,0,10.793,0a.745.745,0,0,0-.621.525L7.259,6.447.764,7.4C.239,7.45,0,7.641,0,7.976A.9.9,0,0,0,.334,8.6l4.728,4.585-1.146,6.5a.972.972,0,0,1-.048.239.807.807,0,0,0,.143.478.466.466,0,0,0,.43.191,1.245,1.245,0,0,0,.525-.143l5.826-3.056L16.62,20.44a1.089,1.089,0,0,0,.525.143.388.388,0,0,0,.382-.191.807.807,0,0,0,.143-.478v-.239l-1.1-6.5L21.3,8.6A1.419,1.419,0,0,0,21.586,7.976Z"
                    transform="translate(0 0)"
                    fill="#ebb12c"
                  />
                </svg>
              ))}
          </div>

          <div className="packDet__duration">
            <p className="heading-sm" style={{ marginBottom: ".7rem" }}>
              Duration
            </p>
            <p className="para-blk">{data.Duration}</p>
          </div>

          <div className="packDet__incl">
            <p className="heading-sm" style={{ marginBottom: "2.7rem" }}>
              Whats Included ?
            </p>

            <div className="packDet__incl-wrapper">
              {Array.isArray(data.includes)
                ? data.includes.map((data,ind) => (
                    <div className="packDet__incl-card" key={ind}>
                      <p className="para-black">{data.title}</p>
                      <p className="para-small">{data.description}</p>
                    </div>
                  ))
                : ""}
            </div>

            <div className="packDet__quickDate">
              <p className="heading-sm" style={{ marginBottom: "2.7rem" }}>
                Quick Date
              </p>
              <div className="packDet__quickDate-wrapper">
              {
                Array.isArray(quickDates) ?
                  quickDates.map((date,ind) => (
                    <PackQuickDates key= {ind} setQuickId= {setQuickId} quickDateId= {date.id} setPackId= {setPackId} packId= {date.package_id} setFormState= {setFormState} setBookModal= {setBookModal} startDate = {date.stdate} endDate= {date.enddate} rate= {date.rate} occupancy= {date.occupancy} setStDate= {setStDate} setEndDate= {setEndDate} setRate= {setRate} setOccu= {setOccu} />
                  ))
                : ''
              }
            
              </div>
            </div>

            <div className="packDet__date">
              <p className="heading-sm" style={{ marginBottom: "2.7rem" }}>
                Custom Date
              </p>
              <div className="packDet__date-wrapper">
                <div className="packDet__date-field">
                  <label
                    htmlFor="duration"
                    className="label-color"
                    style={{ marginBottom: "7px" }}
                  >
                    Choose Duration
                  </label>
                  <input
                    type="text"
                    id="duration"
                    placeholder="Days"
                    className="input-norm"
                    style= {{cursor: 'default'}}
                    value= {moment(endDate).diff(moment(stDate), 'days')}
                  />
                </div>

                <div className="packDet__date-field">
                  <label
                    htmlFor="dateChosen"
                    className="label-color"
                    style={{ marginBottom: "7px" }}
                  >
                    Pick Your Date
                  </label>
                  <ReactDatePicker
                    startDate={stDate}
                    endDate={endDate}
                    selected={stDate}
                    selectsRange
                    minDate={new Date()}
                    onChange={onDateChange}
                  />
                </div>

                <div className="packDet__date-field">
                  <label
                    htmlFor="occupancy"
                    className="label-color"
                    style={{ marginBottom: "7px" }}
                  >
                    Person
                  </label>
                  <input
                    type="number"
                    id="occupancy"
                    placeholder="Adults"
                    className="input-norm"
                    onChange= {(e) => setOccu(e.target.value)}
                    value= {occu}
                  />
                </div>
              </div>
            </div>
            
            <div className="packDet__btnWrapper">
              <button className="btn-color" style= {{marginBottom: '4rem', marginRight: 'auto'}} onClick= {handleEnq} >Book Now</button>
            </div>

            <div className="packDet__images">
              {Array.isArray(data.photos)
                ? data.photos.map((ph) => (
                    <img
                      key={ph.id}
                      src={`${process.env.REACT_APP_BASEURL}images/`+ph.image}
                      alt="Package"
                      className="packDet__image"
                    />
                  ))
                : ""}
            </div>
          </div>
        </div>
      </section>
      <PackagePop 
        packId= {packId}
        quickId= {quickId}
        bookModal= {bookModal} 
        setBookModal= {setBookModal} 
        name= {name}
        setName= {setName}
        email= {email}
        setEmail= {setEmail}
        phone= {phone}
        setPhone= {setPhone}
        occu= {occu}
        setOccu= {setOccu}
        rate= {rate}
        setRate= {setRate}
        stDate= {stDate}
        endDate= {endDate}
        formState= {formState}
        esewaCode= {esewaCode}
        bgImg= {data.coverImage}
       />
    </>
  );
}

export default PackDet;