import React from 'react';
import { Link } from 'react-router-dom';

function FeaturedCard({id, coverImage, title, companyName, about, duration, includes, photos}) {
    return (
        <>
            <div className="featured__card">
                <img src={`${process.env.REACT_APP_BASEURL}images/`+coverImage} alt="" className="featured__card-img" />

                <div className="featured__card-content">
                    <p className="heading-med">{title}</p>
                    <p className="para-deals" style= {{marginBottom: '15px'}}>{companyName}</p>
                    <p className="para-blk" style= {{marginBottom: '25px'}}>
                        {about}
                    </p>
                    <p className="heading-sm" style= {{marginBottom: '7px'}}>Duration</p>  
                    <div className="featured__card-wrap">
                        <p className="para-cate">{duration}</p>
                    </div>
                    <p className="heading-sm" style= {{marginBottom: '7px'}}>Included</p>
                    <div className="featured__card-wrap">
                        {
                            Array.isArray(includes) ?
                                includes.map((incl,i) => (
                                    <p className="para-cate" key={i}>{incl.title}</p>
                                ))
                            : ''
                        }
                    </div>
                    <div className="featured__card-photos">
                        {
                            Array.isArray(photos) ?
                                photos.map((p,i) => (
                                    <img key= {i} src={`${process.env.REACT_APP_BASEURL}images/`+p.image} alt="featured" className="featured__card-photo" />
                                ))
                            : ''
                        }
                    </div>
                    <Link exact to= {`/package-details/${id}`} className="btn-explore" >
                        <p className="para-reg" style= {{margin: '0px'}}>Explore</p>
                        <div className="btn-explore--btnSvg">
                            <svg id="ic_keyboard_arrow_right_24px" xmlns="http://www.w3.org/2000/svg" width="8.794" height="14.241" viewBox="0 0 8.794 14.241">
                                <path id="Path_4" data-name="Path 4" d="M8.59,18.318l5.435-5.447L8.59,7.423,10.263,5.75l7.121,7.121-7.121,7.121Z" transform="translate(-8.59 -5.75)" fill="#fff"/>
                            </svg>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default FeaturedCard
