import Home from "./pages/Home";
import "./styles/App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Activity from "./pages/Activity";
import PackDet from "./pages/PackDet";
import CalendarPage from "./pages/CalendarPage";

function App() {
  return (
    <>
    <Router>
      <Switch>
        <div className="container">
          <Route path="/" exact component={Home} />
          <Route path="/company/:id" exact component={Activity} />
          <Route path="/package-details/:id" exact component={PackDet} />
          <Route path="/calendar" exact component={CalendarPage} />
        </div>
      </Switch>
    </Router>
    </>
  );
}

export default App;
