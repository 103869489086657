import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Deals from '../components/Deals'
import Explore from '../components/Explore'
import Featured from '../components/Featured'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Offer from '../components/Offer'
import Popular from '../components/Popular'
import Recent from '../components/Recent'
import Testimonials from '../components/Testimonials'

function Home() {

    const [compData,setCompData] = useState('')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}api/getTopDeals`)
            .then((res) => {
                setCompData(res.data.data)
            })
    },[])

    return (
        <>
            <Navbar/>
            <Header/>
            <Testimonials/>
            <Deals monthlyData= {compData.monthsTopDeals} featuredData= {compData.featuredTopDeals}  />
            <div className="home__categories">
                <Popular data= {compData.mostpopular} />
                <Recent data= {compData.trending} />
            </div>
            <Featured/>
            <Offer/>
            <Explore/>
        </>
    )
}

export default Home
