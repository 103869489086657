import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

import 'react-big-calendar/lib/sass/styles.scss'
import axios from 'axios';
import Navbar from '../components/Navbar';

function CalendarPage() {
    const localizer = momentLocalizer(moment);

    const [events, setEvents] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}api/getEvents`)
            .then((res) => {
                setEvents(res.data.data)
            })
            .catch((e) => {
                console.log(e)
            })
    }, [])

    return (
        <>
            <Navbar/>
            <div className="calendarPage">
                <Calendar
                    localizer={localizer}
                    events={Array.isArray(events) ? events : [] }
                    startAccessor="start"
                    endAccessor="end"
                    views={{ month: true}}
                />
            </div>
        </>
    )
}

export default CalendarPage
