import React from "react";
import { Link } from "react-router-dom";

function ProdCard({ data }) {
  return (
    <>
      <Link className= "prodCard__link" exact to={`/package-details/${data ? data.id : 0}`}>
        <div className="prodCard">
          <img src={`${process.env.REACT_APP_BASEURL}images/`+data.coverImage} alt="Product" className="prodCard__img" />
          <div className="prodCard__content">
            <p className="test-name" style={{ marginBottom: "7px" }}>
              {data.title}
            </p>
            <div className="prodCard__rating">
              {Array(data ? Number(data.rating) : 0)
                .fill()
                .map((d,i) => (
                  <svg
                    id="Group_258"
                    data-name="Group 258"
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.586"
                    height="20.584"
                    viewBox="0 0 21.586 20.584"
                    key= {i}
                  >
                    <path
                      id="Path_23"
                      data-name="Path 23"
                      d="M21.586,7.976q0-.5-.716-.573l-6.5-.955L11.414.525A.745.745,0,0,0,10.793,0a.745.745,0,0,0-.621.525L7.259,6.447.764,7.4C.239,7.45,0,7.641,0,7.976A.9.9,0,0,0,.334,8.6l4.728,4.585-1.146,6.5a.972.972,0,0,1-.048.239.807.807,0,0,0,.143.478.466.466,0,0,0,.43.191,1.245,1.245,0,0,0,.525-.143l5.826-3.056L16.62,20.44a1.089,1.089,0,0,0,.525.143.388.388,0,0,0,.382-.191.807.807,0,0,0,.143-.478v-.239l-1.1-6.5L21.3,8.6A1.419,1.419,0,0,0,21.586,7.976Z"
                      transform="translate(0 0)"
                      fill="#ebb12c"
                    />
                  </svg>
                ))}
            </div>
            <p className="para-small">{data.subtitle}</p>
            <p className="para-small--bold">{data.companyName}</p>
          </div>
        </div>
      </Link>
    </>
  );
}

export default ProdCard;
