import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Navbar() {
  const [navbar, setNavbar] = useState(false);

  let vh = window.innerHeight * .0001;
  let secondLevelLocation = ''
  document.documentElement.style.setProperty('--vh', `${vh}px`);  

  let pathArray = window.location.pathname.split('/');
  secondLevelLocation = pathArray[1];

  const changeNav = () => {
          if(window.scrollY >=vh*2) {
            setNavbar(true);
          }else if(window.innerWidth<=700){
            setNavbar(true)
          }
          else {
            setNavbar(false);
          }
  }
  
  useEffect(() => {
      if(secondLevelLocation.length <= 0) {
          window.addEventListener('scroll', changeNav)
        }else {
            setNavbar(true);
        }

  },[])

    return (
        <header className= {navbar ? 'navbar navbar__sticky' : 'navbar'}>
            <Link to= "/" className='navbar__title navbar__item'>Eco Adventure Center</Link>
            <Link to= "/" className='navbar__item'>Home</Link >
            <Link to="/calendar" className='navbar__item'>Fix Departure</Link>
        </header>
    )
}

export default Navbar
