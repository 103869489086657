import React from 'react'


function OfferCard({name,title,image}) {
    return (
        <>
            <div className="offer__card">
                <img src={`${process.env.REACT_APP_BASEURL}/images/${image}`} alt="offerImg" className="offer__card-img" />
                <div className="offer__card-text">
                    <p className="para-wh">{name}</p>
                    <p className="heading-med--wh">{title}</p>
                </div>
            </div>
        </>
    )
}

export default OfferCard
