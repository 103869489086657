import axios from 'axios'
import React, { useEffect, useState } from 'react'
import OfferCard from './OfferCard'

function Offer() {

    const [offers,setOffers] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}api/getOffers`)
                .then((res) => {
                    setOffers(res.data.data)
                })
                .catch((e) => {
                    console.log(e)
                })
    },[])

    return (
        <>
            <div className="offer">
                <div className="offer__container">
                    <p className="heading-main" style={{marginBottom: "20px"}}>What we offer</p>
                    <div className="offer__para" style={{marginBottom: "40px"}}>
                        <p className="para-blk">Grab the top deals of the month in the adventure 
                        stays and more. The most exciting Packages</p>
                    </div>

                    <div className="offer__content">
                        {
                            offers || Array.isArray(offers) ?
                            offers.map((v,i) => (
                                <OfferCard key={i} name={v.companyName} title={v.title} image={v.image} />

                            ))
                            :''
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Offer
