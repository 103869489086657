import React from "react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Link } from "react-router-dom";

function DealSlider({ cate, data }) {
  SwiperCore.use([Navigation, Autoplay]);
  
  return (
    <>
      <div className="deals__slider">
        <Swiper
          spaceBetween={30}
          slidesPerView={3}
          loop={true}
          navigation={{
            prevEl: `.${cate}__leftBtn`,
            nextEl: `.${cate}__rightBtn`,
          }}
          autoplay={{ delay: 4500, disableOnInteraction: true }}
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
          {Array.isArray(data)
            ? data.map((d, i) => (
                <SwiperSlide key={i}>
                  <Link className="deals__link" exact to={`/package-details/${d.id}`}>
                    <div className="deals__slide">
                      <img
                        src={`${process.env.REACT_APP_BASEURL}images/`+d.coverImage}
                        alt=""
                        className="deals__slide-img"
                      />
                      <div className="deals__slide--text">
                        <p className="test-name">{d.title}</p>
                        <p className="para-deals">{d.subtitle}</p>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))
            : ""}
        </Swiper>
        <div className={`dealsSlider__leftBtn ${cate}__leftBtn`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.674"
            height="36.094"
            viewBox="0 0 22.674 36.094"
          >
            <g
              id="Group_272"
              data-name="Group 272"
              transform="translate(319.247 -332.684) rotate(179)"
            >
              <g
                id="ic_keyboard_arrow_right_24px"
                transform="translate(302.958 -363.161)"
              >
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M8.59,37.268,22.221,23.607,8.59,9.946l4.2-4.2L30.644,23.607,12.786,41.465Z"
                  transform="translate(-8.59 -5.75)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className={`dealsSlider__rightBtn ${cate}__rightBtn`}>
          <svg
            id="ic_keyboard_arrow_right_24px"
            xmlns="http://www.w3.org/2000/svg"
            width="22.054"
            height="35.715"
            viewBox="0 0 22.054 35.715"
          >
            <path
              id="Path_4"
              data-name="Path 4"
              d="M8.59,37.268,22.221,23.607,8.59,9.946l4.2-4.2L30.644,23.607,12.786,41.465Z"
              transform="translate(-8.59 -5.75)"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export default DealSlider;
