import React, { useEffect, useState } from 'react'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import ModalVideo from 'react-modal-video'
import  { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Mousewheel } from 'swiper';
import axios from 'axios';
import ExploreVideo from './ExploreVideo'

function Explore() {
    SwiperCore.use([Autoplay, Mousewheel]);

    const [vid,setVid] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}api/getExploreVideos`)
            .then((res) => {
                setVid(res.data.data.exploreVideos)
                console.log(res.data.data)
            })
            .catch((e) => {
                console.log(e)
            })

    }, [])

    const[videoId,setVideoId] = useState('')
    const [isOpen, setOpen] = useState(false)
            

    return (
        <>
            <div className="explore">
                <p className="heading-main" style= {{marginBottom: '30px'}}>Explore our videos</p>
                {
                    vid ?
                    vid.length > 4 ?
                    <div className="explore__container">
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={4}
                            freeMode={true}
                            loop={true}
                            centeredSlides={true}
                            mousewheel={true}
                            autoplay= {
                                {
                                    delay: 2500,
                                    disableOnInteraction: true,
                                }
                            }
                            breakpoints={{
                                "300": {
                                    "slidesPerView": 2,
                                    "spaceBetween": 30
                                  },
                                "700": {
                                    "slidesPerView": 3,
                                    "spaceBetween": 30
                                  },
                                "1024": {
                                  "slidesPerView": 4,
                                  "spaceBetween": 30
                                }
                            }}
                        >   
                            
                            {
                                vid || Array.isArray(vid) ?
                                vid.map((v,i) => (
                                    <SwiperSlide key= {i}>
                                   <ExploreVideo setVideoId= {setVideoId} setOpen= {setOpen} v={v} i={i} />
                                   </SwiperSlide>
                                ))
                                :''
                            }
                        </Swiper>
                    </div>
                    : 
                    <div className="explore__container explore__container--grid">
                            {
                                vid || Array.isArray(vid) ?
                                vid.map((v,i) => (
                                <ExploreVideo key={i} setVideoId= {setVideoId} setOpen= {setOpen} v={v} i={i} />
                                ))
                                :''
                            }
                    </div>
                    : ''
                }
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)} />
        </>
    )
}

export default Explore
