import axios from 'axios';
import React, { useEffect, useState } from 'react';
import HotDealCard from '../components/HotDealCard';
import Navbar from '../components/Navbar';
import ProdCard from '../components/ProdCard';

function Activity({ match }) {

    const {params: { id }} = match;
    
    const [data, setData] = useState("");
    const [services, setServices] = useState("");
    const [hotDeals, setHotDeals] = useState("");
    const [recent, setRecent] = useState("");
    const [allPkg, setAllPkg] = useState("");

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}api/getCompanies/${id}`)
            .then((res) => {
                setData(res.data.data.company);
                setServices(res.data.data.services)
            })
        
        axios.get(`${process.env.REACT_APP_BASEURL}api/getCompanyHotDeals/${id}`)
            .then((res) => {
                setHotDeals(res.data.data)
            })
        
        axios.get(`${process.env.REACT_APP_BASEURL}api/getPackages/${id}/recent`)
            .then((res) => {
                setRecent(res.data.data)
            })
        
        axios.get(`${process.env.REACT_APP_BASEURL}api/getPackages/${id}/all`)
            .then((res) => {
                setAllPkg(res.data.data)
            })
    },[])



    return (
        <>
            <Navbar/>
            <section className="activity">
                {
                    data || Array.isArray(data) ?
                     

                            <div className="activity__container">
                                <div className="activity__head">
                                    <div className="activity__head-desc">
                                        <img src={`${process.env.REACT_APP_BASEURL}images/`+data[0].bannerImage} alt="" className="activity__head-img" />
                                        <div className="activity__head-text">
                                            <p className="activity-name" style={{marginBottom: "4px"}}>{data[0].companyName}</p>
                                            <p className="activity-loc">{data[0].companyAddress}</p>
                                            <div className="activity__rating">
                                                {Array(data[0] ? Number(data[0].companyStar) : 0)
                                                    .fill()
                                                    .map((d,i) => (
                                                        <svg key= {i} id="Group_258" data-name="Group 258" xmlns="http://www.w3.org/2000/svg" width="21.586" height="20.584" viewBox="0 0 21.586 20.584">
                                                        <path id="Path_23" data-name="Path 23" d="M21.586,7.976q0-.5-.716-.573l-6.5-.955L11.414.525A.745.745,0,0,0,10.793,0a.745.745,0,0,0-.621.525L7.259,6.447.764,7.4C.239,7.45,0,7.641,0,7.976A.9.9,0,0,0,.334,8.6l4.728,4.585-1.146,6.5a.972.972,0,0,1-.048.239.807.807,0,0,0,.143.478.466.466,0,0,0,.43.191,1.245,1.245,0,0,0,.525-.143l5.826-3.056L16.62,20.44a1.089,1.089,0,0,0,.525.143.388.388,0,0,0,.382-.191.807.807,0,0,0,.143-.478v-.239l-1.1-6.5L21.3,8.6A1.419,1.419,0,0,0,21.586,7.976Z" transform="translate(0 0)" fill="#ebb12c"/>
                                                        </svg>
                                                ))}
                                                <p className="activity-loc">211 Reviews</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="activity__head-social">
                                        <a href= {data[0].websiteURL} className="btn-social" style={{backgroundColor: "#52BF74"}}>Website</a>
                                        <a href= {data[0].facebookURL} className="btn-social" style={{backgroundColor: "#6273CC"}}>Facebook</a>
                                        <a href= {data[0].instaURL} className="btn-social" style={{backgroundColor: "#F45C85"}}>Instagram</a>
                                    </div>
                                </div>
            
                                <div className="activity__desc">
                                    <p className="para-blk">
                                        {data[0].companyAbout}
                                    </p>
                                </div>
            
                                <div className="activity__amenities">
                                    {
                                        Array.isArray(services) ? 
                                        services.map((s,i) => (
                                            <div className="activity__amen" key= {i}>
                                                <img src={`${process.env.REACT_APP_BASEURL}images/`+s.image} alt="icon" className="activity__amen-icon" />
                                                <p className="para-blk">{s.title}</p>
                                            </div>
                                        ))
                                        : "no services"
                                    }
                                </div>
                                
                                {
                                    Array.isArray(hotDeals) ?
                                        hotDeals.length > 0 ?
                                        <div className="activity__deals">
                                            <p className="heading-main" style= {{marginBottom: '30px'}}>Hot Deals</p>
                                            <div className="activity__deals-wrapper">
                                                {
                                                    hotDeals.map((d,i) => (
                                                        <HotDealCard key= {i} id= {d.id} title= {d.title} coverImage= {d.coverImage}/>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    : ''
                                    : ''
                                }
                                
                                {
                                    Array.isArray(recent) ?
                                        recent.length > 0 ?
                                        <div className="activity__recent">
                                            <p className="heading-main" style= {{marginBottom: '30px'}}>Recently Added</p>
                                            <div className="activity__recent-wrapper">
                                            {
                                                recent.map((d,i) => (
                                                   <ProdCard data= {d} key= {i}/>
                                                    
                                                ))
                                            }
                                            </div>
                                        </div>
                                    :''
                                    :''
                                }
                               
                                {
                                    Array.isArray(allPkg) ?
                                        allPkg.length > 0 ?
                                        <div className="activity__packages">
                                            <p className="heading-main" style= {{marginBottom: '30px'}}>All Packages</p>
                                            <div className="activity__packages-wrapper">
                                            {
                                                allPkg.map((d,i) => (
                                                    <ProdCard data= {d} key= {i}/>
                                                     
                                                 ))
                                            }
                                            </div>
                                        </div>
                                    :''
                                    :''
                                }
                            </div>
                    : ''
                }
            </section>
        </>
    )
}

export default Activity
