import React from 'react'
import DealSlider from './DealSlider'

function Deals({monthlyData, featuredData}) {
    return (
        <>
            <section className="deals">
                <div className="deals__row">
                    <div className="deals__text">
                        <p className="heading-main" style={{marginBottom: "20px"}}>This Month’s Top Deals</p>
                        <p className="para-blk">Grab the top deals of the month in the adventure stays and more.</p>
                    </div>
                    <DealSlider data= {monthlyData} cate="deals"/>
                </div>

                <div className="deals__row">
                    <div className="deals__text">
                        <p className="heading-main" style={{marginBottom: "20px"}}>Featured Top Deals</p>
                        <p className="para-blk">Grab the top deals of the month in the adventure stays and more.</p>
                    </div>
                    <DealSlider data= {featuredData} cate="featured"/>
                </div>
            </section>
        </>
    )
}

export default Deals
